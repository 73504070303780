import { debounce } from '@/helpers/utils';

export default defineNuxtPlugin(() => {
  const setVhProperty = () => {
    const height = window.visualViewport?.height;

    const vh = height ? `${height * 0.01}px` : '1vh';
    document.documentElement.style.setProperty('--vh', vh);
  };

  setVhProperty();

  window.visualViewport?.addEventListener(
    'resize',
    debounce(setVhProperty, 100)
  );
});

const getCookie = (cookies: string, key: string) => {
  const decodedCookies = decodeURIComponent(cookies);
  const name = `${key}=`;
  const ca = decodedCookies.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }

  return '';
};

const setCookie = (key: string, value: string, maxAge: number) => {
  if (process.client) {
    document.cookie = `${key}=${value}; Max-Age=${maxAge}; path=/`;
  }
};

const deleteCookie = (
  key: string,
  { domain, path = '/' }: { path?: string; domain?: string }
) => {
  if (process.client) {
    document.cookie = `${key}=; Max-Age=0; path=${path}${
      domain ? `; domain=${domain}` : ''
    }`;
  }
};

export { getCookie, setCookie, deleteCookie };

import type { RouteLocationNormalized } from 'vue-router';

type PageTitles = { [locale: string]: { [key: string]: string } };
type PageSlugTranslations = { [key: string]: { [locale: string]: string } };

const getInfoPageByKey = ({
  pageTitles,
  pageSlugTranslations,
  key,
  locale,
}: {
  pageTitles: PageTitles;
  pageSlugTranslations: PageSlugTranslations;
  key: keyof typeof pageSlugTranslations;
  locale: keyof typeof pageTitles;
}) => {
  const slug = pageSlugTranslations[key][locale];
  const title = pageTitles[locale][slug];
  return { key, slug, title };
};

const pages = ({
  pageTitles,
  locale,
}: {
  pageTitles: PageTitles;
  locale: keyof typeof pageTitles;
}) =>
  Object.keys(pageTitles[locale]).map((slug) => ({
    slug,
    // @ts-ignore
    title: pageTitles[locale][slug],
    metaDesc: slug === 'cookie-policy' ? pageTitles[locale][slug] : '',
  }));

const getPageContent = async ({
  pageTitles,
  slug,
  locale,
}: {
  pageTitles: PageTitles;
  slug: string;
  locale: keyof typeof pageTitles;
}) => {
  const runtimeConfig = useRuntimeConfig();

  const { clientUrl } = runtimeConfig.public;
  const pageContent =
    slug === 'cookie-policy'
      ? ''
      : await $fetch<string>(
          `${clientUrl}/ecom-assets/content/info/${locale}/${slug}.html`
        );

  return {
    slug,
    // @ts-ignore
    title: pageTitles[locale][slug],
    content: pageContent,
    metaDesc: slug === 'cookie-policy' ? pageTitles[locale][slug] : '',
  };
};

const getDefaultLocalizedPage = ({
  pageTitles,
  to,
}: {
  pageTitles: PageTitles;
  to: RouteLocationNormalized;
}) => {
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale.value as keyof typeof pageTitles;

  const countryConfig = useShopCountryConfig();
  const language = useLanguage();

  const { slug } = to.params;

  const redirectPage = `/${countryConfig.rootPath[language]}/info/${
    Object.keys(pageTitles[locale])[0]
  }`;

  const from = (pageTitles[locale] as any)[slug as string];

  if (from === undefined) {
    return navigateTo(redirectPage, {
      redirectCode: 301,
    });
  }
  return true;
};

export { pages, getInfoPageByKey, getPageContent, getDefaultLocalizedPage };

import { pageViewEvent } from '@/utils/tracking-utils';

const excludedRoutesForPageViews = [
  'shop-brands-slug',
  'shop-slug',
  'shop-product-slug',
  'shop',
  'shop-receipt',
  'shop-search',
  'shop-campaigns',
  'shop-campaigns-slug',
];

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const gtm = useGtm();
  const nuxtApp = useNuxtApp();

  router.afterEach((to) => {
    const routeBaseName = nuxtApp.$getRouteBaseName(to) || '';

    if (!excludedRoutesForPageViews.includes(routeBaseName)) {
      gtm?.trackEvent(pageViewEvent());
    }
  });
});

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  type NormalizedCacheObject,
  type DefaultContext,
} from '@apollo/client/core';
import { Observable } from '@apollo/client/utilities';
import { useAuthStore } from '~/stores/auth';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist';
import { IDS_AUTH_TOKEN_HEADER_NAME } from '../../shared/config/ids-auth-config';

const tokenMaxAge = 60 * 60 * 24 * 7; // 7 days

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const { isAccountsEnabled } = useRuntimeConfig().public.featureFlags;

  const fetchToken = async (baseUrl: string) => {
    const response = await $fetch<{ data: { token: string } }>(
      `${baseUrl}/rest/api-token`,
      {
        method: 'GET',
        credentials: 'include',
      }
    );

    return response.data.token;
  };

  let apolloClient: ApolloClient<NormalizedCacheObject>;
  let persistor: CachePersistor<NormalizedCacheObject>;

  const apollo = () => apolloClient;

  const initApollo = async (
    brand?: ShopBrand,
    regionCode?: RegionCode,
    language?: Language
  ) => {
    const languageValue = language === undefined ? 'sv' : language;

    const authCookie = useCookie(
      `gql-token-${brand}-${regionCode}-${languageValue}`,
      {
        maxAge: tokenMaxAge,
      }
    );

    const baseUrl = nuxtApp.$config.public.apiUrl[regionCode];

    if (apolloClient) {
      await apolloClient.clearStore();
      apolloClient.stop();
    }

    const authLink = setContext(async () => {
      if (!authCookie.value) {
        authCookie.value = (await fetchToken(baseUrl)) || '';
      }

      const headers: {
        Authorization: `Bearer ${string}`;
        [IDS_AUTH_TOKEN_HEADER_NAME]?: string;
      } = {
        Authorization: `Bearer ${authCookie.value}`,
      };

      // Set account auth token if logged in, before making any request
      if (isAccountsEnabled) {
        try {
          const authStore = useAuthStore();
          const accountAuthToken = useAccountAuthTokenCookie();

          await authStore.tryLogin();

          if (accountAuthToken.value) {
            headers[IDS_AUTH_TOKEN_HEADER_NAME] =
              `Bearer ${accountAuthToken.value}`;
          }
        } catch {}
      }

      return {
        headers,
      };
    });

    // eslint-disable-next-line consistent-return
    const errorLink = onError(({ forward, graphQLErrors, operation }) => {
      const response: any = graphQLErrors?.[0]?.extensions?.response;
      const status = response?.status;

      // Fetch new token on 401
      if (status === 401) {
        return new Observable((observer) => {
          fetchToken(baseUrl)
            .then((newToken) => {
              authCookie.value = newToken;

              operation.setContext(({ headers }: DefaultContext) => ({
                headers: {
                  ...headers,
                  Authorization: newToken ? `Bearer ${newToken}` : null,
                },
              }));
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              // No refresh or client token available
              observer.error(error);
            });
        });
      }

      if (status === 404) {
        nuxtApp.$toast?.error(
          graphQLErrors?.[0]?.message ||
            nuxtApp.$i18n?.t('error.something_went_wrong')
        );
      }
    });

    const httpLink = createHttpLink({
      uri: `${baseUrl}/graphql`,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const cache = new InMemoryCache();
    if (process.client) {
      persistor = new CachePersistor({
        key: `apollo-cache-${brand}-${regionCode}-${languageValue}`,
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
      });
      await persistor.restore();
    }

    apolloClient = new ApolloClient({
      cache,
      link: from([authLink, errorLink, httpLink]),
      ssrMode: true,
    });
  };

  return {
    provide: {
      initApollo,
      apollo,
    },
  };
});

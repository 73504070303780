export default defineNuxtPlugin((nuxtApp) => {
  document.addEventListener('afterTriggerbeeReady', () => {
    try {
      nuxtApp.$apiFetch('/rest/triggerbee/cookies', {
        method: 'GET',
        credentials: 'include',
      });
    } catch {}
  });
});

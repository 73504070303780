/* eslint-disable consistent-return */
export default defineNuxtRouteMiddleware((to) => {
  // Redirect routes with trailing slash to routes without for better SEO and also remove trailing slash from query parameters

  // Check if the fullPath ends with a slash and is not just a single '/'
  if (to.fullPath !== '/' && to.fullPath.endsWith('/')) {
    // Split the URL into path and query
    const [pathWithoutQuery, queryString] = to.fullPath.split('?');

    // Remove trailing slashes from the path
    const path = pathWithoutQuery.replace(/\/+$/, '');

    // Process query parameters
    const queryParams = new URLSearchParams(queryString || '');
    Array.from(queryParams.entries()).forEach(([key, value]) => {
      if (value.endsWith('/')) {
        queryParams.set(key, value.replace(/\/+$/, ''));
      }
    });

    // Reconstruct the full path with cleaned query parameters
    const cleanedQueryString = queryParams.toString();
    const nextFullPath = cleanedQueryString
      ? `${path}?${cleanedQueryString}`
      : path;

    // Prevent open redirect vulnerability
    if (nextFullPath.startsWith('//')) {
      return;
    }

    // Ensure the next path is different before redirecting
    if (nextFullPath !== to.fullPath) {
      // Use navigateTo with the reconstructed full path
      return navigateTo(nextFullPath, { redirectCode: 301 });
    }
  }
});
/* eslint-enable consistent-return */

export default defineNuxtPlugin((nuxtApp) => {
  /**
   * Workaround for Sentry causing non-fatal errrors to show error page
   * Remove once there is a Nuxt 3 module for Sentry
   */
  // eslint-disable-next-line no-param-reassign
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error('errorHandler', error, instance, info);
  };
});

function debounce(callback, wait) {
  let timeout;

  // eslint-disable-next-line func-names
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(this, args), wait);
  };
}

function smoothScrollTo(el, target = 'top') {
  if (!el) {
    return;
  }

  const header = document.getElementById('header');
  const headerOffset = header ? header.getBoundingClientRect().height : 0;

  const y =
    el.getBoundingClientRect()[target] + window.pageYOffset - headerOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

function toggleBodyScroll(shouldScroll, setWidth = true) {
  const body = document.getElementsByTagName('body')[0];
  if (body) {
    // Stop body from jumping around
    if (!shouldScroll && setWidth) {
      const oldWidth = body.getBoundingClientRect().width;
      body.style.width = `${oldWidth}px`;
    } else {
      body.style.width = '';
    }
    body.classList.toggle('overflow-hidden', !shouldScroll);
  }
}

export { debounce, smoothScrollTo, toggleBodyScroll };

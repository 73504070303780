import vClickOutside from 'click-outside-vue3';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vClickOutside);

  nuxtApp.vueApp.directive('scroll-top', {
    bind(el, binding) {
      const scrollTopCallback = binding.value;

      const scrollableElement = binding.modifiers?.window ? window : el;

      let isScrollTop = 0;
      let ticking = false;

      // eslint-disable-next-line no-param-reassign
      el.scrollTopHandler = () => {
        isScrollTop =
          (scrollableElement.scrollTop ?? scrollableElement.scrollY) <= 0;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            ticking = false;
          });
          scrollTopCallback(isScrollTop);
          ticking = true;
        }
      };

      scrollableElement.addEventListener('scroll', el.scrollTopHandler);
    },
    unbind(el, binding) {
      const scrollableElement = binding.window ? window : el;

      scrollableElement.removeEventListener('scroll', el.scrollTopHandler);
    },
  });
});

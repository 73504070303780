import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return;
  const { isAccountsEnabled, isSttExchangeEnabled } =
    useRuntimeConfig().public.featureFlags;

  if (!isAccountsEnabled || !isSttExchangeEnabled) return;

  const { stt } = to.query;

  if (!stt) return;

  const authStore = useAuthStore();

  await authStore.loginWithShortTermToken(stt as string);

  // eslint-disable-next-line consistent-return
  return navigateTo({ ...to, query: { ...to.query, stt: undefined } }); // Remove STT from param now that we've exchanged it
});

import { defineStore } from 'pinia';
import { gql } from '@apollo/client/core';
import type { Order as GqlOrder } from '#root/shared/types/graphql-types';
import { formatISO, endOfDay, startOfDay, subMonths } from 'date-fns';

// Type Definitions
type SimpleOrder = {
  id: number;
  date: Date;
  images: string[];
  statusId: number;
  itemsQuantity: number;
};

type OrderEdge = {
  node: GqlOrder;
};

interface OrderData {
  orders: {
    edges: OrderEdge[];
  };
}

export interface OrderHistoryStoreState {
  orderHistory: Array<SimpleOrder>;
  loading: boolean;
  nothingFound: boolean;
  error: boolean;
  showLoadMoreButton: boolean;
  filter: string;
}

const orderResultFormatter = (orders: OrderEdge[]): SimpleOrder[] =>
  orders
    ?.filter((edge) => edge?.node)
    .map((edge) => {
      const orderNode = edge?.node as GqlOrder;
      return {
        id: orderNode.entityId,
        date: new Date(orderNode.createdAt),
        images: orderNode.orderLines?.map((ol) => `${ol.imageUrl}`) || [],
        statusId: orderNode?.status?.entityId || 0,
        itemsQuantity: orderNode.orderLines?.length || 0,
      };
    }) || [];

const getLastOrderId = (orders: SimpleOrder[]): number | undefined =>
  orders?.length ? orders[orders.length - 1]?.id : undefined;

export const useOrderHistory = defineStore('orderHistory', {
  state: (): OrderHistoryStoreState => ({
    orderHistory: [],
    loading: true,
    nothingFound: false,
    error: false,
    showLoadMoreButton: false,
    filter: '',
  }),
  getters: {},
  actions: {
    async getOrderHistory(limit: number, filter: string) {
      if (this.filter !== filter) {
        // When applying a new filter, reset the order history to show loading state and to correctly check condition for showing the load more button
        this.orderHistory = [];
      }

      this.loading = true;
      this.nothingFound = false;
      this.error = false;
      this.showLoadMoreButton = false;
      this.filter = filter;

      const { shopOpened } = useShopCountryConfig();
      const isoDateformatter = (date: Date) =>
        formatISO(date, {
          representation: 'complete',
        });
      const min = () => {
        if (!filter) {
          return isoDateformatter(startOfDay(new Date(shopOpened, 0, 1)));
        }
        const now = new Date();
        switch (filter) {
          case 'all':
            return isoDateformatter(startOfDay(new Date(shopOpened, 0, 1)));
          case '6m':
            return isoDateformatter(startOfDay(subMonths(now, 6)));
          default:
            return isoDateformatter(startOfDay(new Date(Number(filter), 0, 1)));
        }
      };

      const max = () => {
        if (!filter) {
          return isoDateformatter(startOfDay(new Date(shopOpened, 0, 1)));
        }
        const now = new Date();
        switch (filter) {
          case 'all':
            return isoDateformatter(now);
          case '6m':
            return isoDateformatter(now);
          default:
            return isoDateformatter(endOfDay(new Date(Number(filter), 11, 31)));
        }
      };

      const orderHistoryQuery = gql`
        query Orders($min: String, $max: String, $limit: Int) {
          orders(
            params: {
              limit: $limit
              page: 1
              minDateCreated: $min
              maxDateCreated: $max
              isDeleted: false
              isPending: false
            }
          ) {
            edges {
              node {
                entityId
                createdAt
                status {
                  entityId
                }
                orderLines {
                  id
                  imageUrl
                }
              }
            }
          }
        }
      `;

      const orderHistoryResults = await useAsyncQuery<OrderData>({
        query: orderHistoryQuery,
        variables: {
          limit,
          max: max(),
          min: min(),
        },
      });

      this.loading = false;
      const orders = orderHistoryResults?.data?.value?.data?.orders?.edges;
      const error = orderHistoryResults?.error?.value;

      if (error) {
        this.error = true;
        this.orderHistory = [];
        this.showLoadMoreButton = false;
      } else if (!orders || orders.length === 0) {
        this.nothingFound = true;
        this.orderHistory = [];
        this.showLoadMoreButton = false;
      } else {
        const prevLastOrderId = getLastOrderId(this.orderHistory);

        this.orderHistory = orderResultFormatter(orders || []);

        const newLastOrderId = getLastOrderId(this.orderHistory);

        // NOTE: This could hide the load more-button if the new page _only_ has pending orders, resulting in an empty array, even though there are more orders on a following page.
        // This is due to limitations in the BigCommerce API - we can't filter out pending orders using their API so we have to filter them out afterwards.
        // Since this will work in most cases, I'll leave it like this for now but if we want to fix this, we need to make a separate request to get the total count of orders, or always show the load more button.
        this.showLoadMoreButton = prevLastOrderId !== newLastOrderId;
      }
    },
  },
});

const pageTitles = {
  sv: {
    'var-e-handel': 'Vår e-handel',
    'frakt-och-leverans': 'Frakt och leverans',
    'retur-och-reklamation': 'Retur och Reklamation',
    smakgaranti: 'Smakgaranti',
    kopvillkor: 'Köpvillkor',
    'kontakta-oss': 'Kontakta oss',
    'cookie-policy': 'Cookie policy',
    'integritets-policy': 'Integritetspolicy',
    'bonuscard-och-uppfodarrabatt': 'Bonuscard och uppfödarrabatt',
  },
  uk: {
    'about-us': 'About us',
    'shipping-and-delivery': 'Shipping and delivery',
    'returns-and-refunds': 'Returns and refunds',
    'terms-of-purchase': 'Terms of purchase',
    'contact-us': 'Contact us',
    'cookie-policy': 'Cookie policy',
    'privacy-policy': 'Privacy policy',
  },
} as const;

const pageSlugTranslations = {
  'about-us': {
    uk: 'about-us',
    sv: 'var-e-handel',
  },
  'shipping-and-delivery': {
    uk: 'shipping-and-delivery',
    sv: 'frakt-och-leverans',
  },
  'returns-and-refunds': {
    uk: 'returns-and-refunds',
    sv: 'retur-och-reklamation',
  },
  'terms-of-purchase': {
    uk: 'terms-of-purchase',
    sv: 'kopvillkor',
  },
  'contact-us': {
    uk: 'contact-us',
    sv: 'kontakta-oss',
  },
  'cookie-policy': {
    uk: 'cookie-policy',
    sv: 'cookie-policy',
  },
  'privacy-policy': {
    uk: 'privacy-policy',
    sv: 'integritets-policy',
  },
} as const;

export { pageTitles, pageSlugTranslations };

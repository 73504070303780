import { hash } from 'ohash';
import { print } from 'graphql';
import type {
  DocumentNode,
  FetchPolicy,
  ApolloQueryResult,
} from '@apollo/client';
import type { AsyncData, AsyncDataOptions } from 'nuxt/app';
import type { KeysOf } from 'nuxt/dist/app/composables/asyncData';

type UseAsyncDataOptionsApolloQuery<T> = AsyncDataOptions<
  ApolloQueryResult<T>,
  ApolloQueryResult<T>,
  KeysOf<ApolloQueryResult<T>>,
  ApolloQueryResult<T>
>;

export function useAsyncQuery<T>(
  query: {
    query: DocumentNode;
    variables?: object;
    fetchPolicy?: FetchPolicy;
  },
  useAsyncDataOptions?: UseAsyncDataOptionsApolloQuery<T>
): AsyncData<ApolloQueryResult<T>, any> {
  const { $apollo } = useNuxtApp();
  const key = hash({ query: print(query.query), variables: query.variables });
  return useAsyncData<ApolloQueryResult<T>>(
    key,
    () => $apollo().query<T>(query),
    useAsyncDataOptions
  );
}

export function useLazyAsyncQuery<T>(
  query: {
    query: DocumentNode;
    variables?: object;
    fetchPolicy?: FetchPolicy;
  },
  useLazyAsyncDataOptions?: Omit<UseAsyncDataOptionsApolloQuery<T>, 'lazy'>
): AsyncData<ApolloQueryResult<T>, any> {
  const { $apollo } = useNuxtApp();
  const key = hash({ query: print(query.query), variables: query.variables });
  return useLazyAsyncData<ApolloQueryResult<T>>(
    key,
    () => $apollo().query<T>(query),
    useLazyAsyncDataOptions
  );
}

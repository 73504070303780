import { defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import type { Composer } from '@nuxtjs/i18n/dist/runtime/composables';

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n as Composer;

  defineRule('required', required);
  defineRule('email', email);
  defineRule('min', min);

  defineRule('name', (value: string) => {
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!value.trim() || !nameRegex.test(value)) {
      return 'Please provide your name';
    }

    return true;
  });

  defineRule('postcode-uk', (value: string) => {
    // NOTE: KW: Kirkwall (Northern Scotland), BT: Northern Ireland, IM: Isle of Man, JE/GY: Channel Islands, PO3/PO4: Isle of Wight
    const postcodeUkRegex =
      /^(?!KW)(?!BT)(?!IM)(?!JE)(?!GY)(?!PO(3[0-9]|4[0-1]))(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))[ ]{0,1}[0-9][A-Z]{2}|(BT80[ ]{0,1}0JG)$/i;

    if (!value.trim() || !postcodeUkRegex.test(value.trim())) {
      return 'Please provide a valid postcode';
    }

    return true;
  });

  defineRule('postcode-sv', (value: string) => {
    const postcodeSeRegex = /^[1-9]{1}[0-9]{2}[ ]{0,1}[0-9]{2}$/;

    if (!value.trim() || !postcodeSeRegex.test(value.trim())) {
      return 'Vänligen ange ett giltigt postnummer';
    }

    return true;
  });

  configure({
    generateMessage: (context) => {
      // Show error message for field if it exists
      if (i18n.te(`validation.${context.field}`)) {
        return i18n.t(`validation.${context.field}`);
      }

      // Show error message for rule if it exists
      if (i18n.te(`validation.${context.rule?.name}`)) {
        return i18n.t(`validation.${context.rule?.name}`);
      }

      // Fallback to general error message
      return i18n.t('validation.general');
    },
  });
});

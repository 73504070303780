<template>
  <div>
    <NuxtLayout>
      <div>
        <NuxtLoadingIndicator :color="loadingIndicatorColor" />
        <NuxtPage />
      </div>
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
const { $initApollo } = useNuxtApp();
const config = useRuntimeConfig();

await $initApollo(
  config.public.brand as ShopBrand,
  useRegionCode(),
  useLanguage()
);

// Can be imported by tailwind config but will maybe cause bigger bundle size (https://tailwindcss.com/docs/configuration#referencing-in-java-script)
const primaryColorBlue = '#0061FF';
const primaryColorHoverBlue = '#0054DD';

const loadingIndicatorColor = computed(
  () =>
    `repeating-linear-gradient(to right, ${primaryColorBlue} 0%, ${primaryColorHoverBlue} 100%)`
);
</script>

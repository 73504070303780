export const useAccountAuthTokenCookie = () => {
  const { authCookieValidityInDays, defaultCookieDomain } =
    useRuntimeConfig().public;

  return useCookie('ecom-account-token', {
    path: '/',
    domain: defaultCookieDomain,
    maxAge: 60 * 60 * 24 * parseInt(authCookieValidityInDays ?? 29, 10),
  });
};

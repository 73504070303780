import * as Sentry from '@sentry/vue';

// TODO: When nuxt 3 module is ready, use that
// https://github.com/nuxt-community/sentry-module/issues/530

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentryDsn, isProd, isDev, sentryRelease },
  } = useRuntimeConfig();

  Sentry.init({
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn: sentryDsn,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0.2,
    environment: isProd ? 'production' : 'development',
    release: sentryRelease,
    enabled: !!sentryDsn && (isProd || isDev),
  });

  return {
    provide: {
      sentryCaptureException: Sentry.captureException,
    },
  };
});

import { formatPrice as formatPriceUtil } from '#root/shared/utils/price-utils';

export default defineNuxtPlugin(() => ({
  provide: {
    formatPrice: (price: number) => {
      const config = useRuntimeConfig();
      const regionCode = useRegionCode();
      const language = useLanguage();

      return formatPriceUtil(price, {
        brand: config.public.brand as ShopBrand,
        regionCode,
        language,
      });
    },
  },
}));

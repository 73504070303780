import { round } from '#root/shared/utils/math-utils';
import { removeTaxFromGross } from '#root/shared/utils/price-utils';
import type { PetFormStep } from '~/stores/pet';
import { useAuthStore } from '~/stores/auth';
import { getPrimaryBreadcrumb } from '../helpers/breadcrumbs';

interface CheckoutSteps {
  REVIEW_CART: number;
  POSTAL_CODE: number;
  SHIPPING?: number;
  PAYMENT_INFO: number;
  RECEIPT: number;
}

type CheckoutStep = keyof CheckoutSteps;

const checkoutSteps = (step: CheckoutStep) => {
  const { checkoutTrackingSteps } = useShopCountryConfig();

  return checkoutTrackingSteps[step];
};

const originalLocation = () => useCookie('originalLocation')?.value || null;

const priceExTax = (prices: any) => {
  const { taxRate } = useShopCountryConfig();

  return prices.salePrice?.value
    ? removeTaxFromGross(prices.salePrice?.value || 0, taxRate)
    : removeTaxFromGross(prices.basePrice?.value || 0, taxRate);
};

const currencyCode = () => useShopCountryConfig().currency.code;

const clearEcommerceData = () => {
  // eslint-disable-next-line func-names
  window?.dataLayer?.push(function () {
    // @ts-ignore
    this.reset();
  });
};

const setUserIdInDataLayer = (id: string | null) => {
  if (process.client) {
    window.dataLayer?.push({ ids_user_id: id });
  }
};

const pageViewEvent = () => {
  clearEcommerceData();

  const route = useRoute();
  const router = useRouter();
  const nuxtApp = useNuxtApp();

  const pageUrl = route.fullPath;
  const routeName = nuxtApp.$getRouteBaseName(route) || '';
  const previousPagePath = router.options.history.state.back as string;

  const authStore = useAuthStore();
  const userId = authStore.profile?.userId || authStore.user?.id || null;

  setUserIdInDataLayer(userId);

  return {
    event: 'pageView',
    pageUrl,
    routeName,
    originalLocation: originalLocation(),
    pageReferrer: getPreviousPageUrl(previousPagePath),
    pageLocation: window.location.href,
  };
};

const productTrackingData = (product: any, position?: number | string) => {
  const primaryBreadcrumb =
    product.primaryBreadcrumb ||
    (product.categories ? getPrimaryBreadcrumb(product) : []);

  const productData: any = {
    id: `${product.entityId}`,
    variantId: `${product.variantId || product.defaultVariant?.entityId}`,
    name: product.name,
    price: (
      product.prices?.salePrice?.value || product.prices?.basePrice?.value
    )?.toString(),
    priceExTax: round(priceExTax(product.prices)),
    brand: product.brand?.name || '',
    category: primaryBreadcrumb?.length
      ? [...primaryBreadcrumb].pop()?.longName?.replace(/\//g, ' > ') || ''
      : '',
  };

  if (position) {
    productData.position = position;
  }

  return productData;
};

/**
 * Action: View product list page (category/brand)
 * Ecommerce Measurement: impressions
 */
const productListEvent = (impressions: any[]) => ({
  ...pageViewEvent(),
  ecommerce: {
    currencyCode: currencyCode(),
    impressions,
  },
});

/**
 * Action: Click product link
 * Ecommerce Measurement: click
 */
const productClickEvent = (products: any[], list: string) => ({
  event: 'productClick',
  ecommerce: {
    currencyCode: currencyCode(),
    click: {
      actionField: { list },
      products,
    },
  },
});

/**
 * Action: View product page
 * Ecommerce Measurement: detail
 */
const productDetailEvent = (product: any) => ({
  ...pageViewEvent(),
  ecommerce: {
    currencyCode: currencyCode(),
    detail: {
      products: [productTrackingData(product)],
    },
  },
});

/**
 * Action: Add product to cart
 * Ecommerce Measurement: add
 */
const addToCartEvent = (products: any[], list?: string) => {
  const add: any = {
    products,
  };

  if (list) {
    add.actionField = { list };
  }

  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: currencyCode(),
      add,
    },
  };
};

/**
 * Action: Remove product from cart
 * Ecommerce Measurement: remove
 */
const removeFromCartEvent = (products: any) => ({
  event: 'removeFromCart',
  ecommerce: {
    currencyCode: currencyCode(),
    remove: {
      products,
    },
  },
});

// Helper
const getVariantFromOptions = (options: any) =>
  options.reduce(
    (acc: string, o: any) => (acc ? `${acc}, ${o.value}` : `${o.value}`),
    ''
  );

/**
 * Action: Enter checkout step
 * Ecommerce Measurement: checkout
 */
const checkoutEvent = (cartItems: any, step: number, option: string = '') => ({
  event: 'checkout',
  ecommerce: {
    currencyCode: currencyCode(),
    checkout: {
      actionField: { step, option },
      products: cartItems.map((item: any) => ({
        name: item.name,
        id: `${item.productId}`,
        variantId: `${item.variantId}`,
        price: (
          item.prices?.salePrice?.value || item.prices?.basePrice?.value
        )?.toString(),
        priceExTax: priceExTax(item.prices),
        brand: item.brand?.name || '',
        category: item.primaryBreadcrumb,
        variant: item.variantLabels
          ? item.variantLabels.join(', ')
          : getVariantFromOptions(item.options),
        quantity: item.quantity,
      })),
    },
  },
  originalLocation: originalLocation(),
});

/**
 * Action: Make purchase
 * Ecommerce Measurement: purchase
 */
const purchaseEvent = (products: any, order: any, adtraction: any) => ({
  ...pageViewEvent(),
  ecommerce: {
    currencyCode: currencyCode(),
    purchase: {
      actionField: order,
      products: products.map((p: any) => ({
        ...productTrackingData(p),
        variant: p.variantLabels.join(', '),
        id: `${p.productId}`,
        variantId: `${p.variantId}`,
        quantity: p.quantity,
        category: p.primaryBreadcrumb,
      })),
    },
  },
  ...adtraction,
});

const addToFavoritesEvent = (name: string, price: number) => ({
  event: 'addToFavorites',
  productName: name,
  productPrice: price,
  currencyCode: currencyCode(),
});

/**
 * Action: Refund products
 * Ecommerce Measurement: refund
 */
const refundEvent = (
  orderId: number,
  products: { id: string; quantity: number }[]
) => ({
  event: 'refund',
  ecommerce: {
    refund: {
      actionField: { id: orderId },
      products,
    },
  },
});

const getPreviousPageUrl = (previousPagePath: string | undefined) => {
  const isExistingSessionKey = 'isExistingSession';
  const isExistingSession = !!sessionStorage.getItem(isExistingSessionKey);

  sessionStorage.setItem(isExistingSessionKey, 'true');

  // Only return document.referrer when is a new session
  if (!isExistingSession && !previousPagePath) {
    return document.referrer;
  }

  if (isExistingSession && previousPagePath) {
    return `${window.location.origin}${previousPagePath}`;
  }

  return null;
};

/**
 * Action: Add pet
 * Ecommerce Measurement: add pet
 */
const addPetEvent = (userId: string, step: PetFormStep) => ({
  event: 'addPet',
  step,
  userId,
});

/**
 * Action: Click search/category/brand page content card
 */
const productListContentCardClickEvent = (data: {
  list: string;
  name: string;
  brand?: string;
  category?: string;
}) => ({
  event: 'productListContentCardClickEvent',
  ...data,
});

/**
 * Action: Click startpage mobile nav buttons
 */
const startpageMobileNavButtonsClickEvent = (title: string) => ({
  event: 'startpageMobileNavButtonsClickEvent',
  title,
});

export {
  checkoutSteps,
  productTrackingData,
  productListEvent,
  productClickEvent,
  productDetailEvent,
  addToCartEvent,
  addToFavoritesEvent,
  removeFromCartEvent,
  checkoutEvent,
  purchaseEvent,
  pageViewEvent,
  excludedRoutesForPageViews,
  refundEvent,
  addPetEvent,
  productListContentCardClickEvent,
  startpageMobileNavButtonsClickEvent,
};

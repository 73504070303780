import { ofetch } from 'ofetch';

export default defineNuxtPlugin(() => {
  // NOTE: not using nuxtApp from arguments since it isn't typed https://nuxt.com/docs/guide/directory-structure/plugins#typing-plugins
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const regionCode = useRegionCode();

  const { $i18n } = nuxtApp;

  const baseURL = config.public.apiUrl[regionCode];

  const apiFetch = ofetch.create({
    baseURL,

    onResponseError: ({ response }) => {
      if (nuxtApp.$toast) {
        const { $toast } = nuxtApp;

        $toast.clear();
        $toast.error(
          // eslint-disable-next-line no-underscore-dangle
          response?._data?.clientMessage ||
            $i18n.t('error.something_went_wrong')
        );
      }
    },
  });

  return {
    provide: {
      apiFetch,
    },
  };
});

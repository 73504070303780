import { useToast } from 'vue-toast-notification';

const toast = useToast({
  position: 'top-right',
  queue: true,
});

export default defineNuxtPlugin(() => ({
  provide: {
    toast,
  },
}));

import { defineStore } from 'pinia';
import type { LocationQuery } from '#vue-router';

export const useEnabledFeaturesStore = defineStore('enabledFeatures', () => {
  const enabledFeatures = ref(
    (useRuntimeConfig().public.enabledFeatures || '').split(',')
  );
  const hasFeatureQuery = ref(false);

  function queryToEnabledFeatures(query: LocationQuery) {
    const enabledFeaturesQueryValues =
      query.enabledFeatures?.toString().split(',') || [];

    // Concatenate arrays and remove duplicates
    enabledFeatures.value = [
      ...new Set([...enabledFeatures.value, ...enabledFeaturesQueryValues]),
    ];

    const disabledFeaturesQueryValues =
      query.disabledFeatures?.toString().split(',') || [];

    // Remove the disabled features from the enabled features
    enabledFeatures.value = enabledFeatures.value.filter(
      (element) => !disabledFeaturesQueryValues.includes(element)
    );

    hasFeatureQuery.value =
      enabledFeaturesQueryValues.length > 0 ||
      disabledFeaturesQueryValues.length > 0;
  }

  function isFeatureEnabled(feature: string) {
    return enabledFeatures.value.includes(feature);
  }

  return {
    queryToEnabledFeatures,
    isFeatureEnabled,
    hasFeatureQuery,
  };
});

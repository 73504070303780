import { configure } from 'vee-validate';
import { useCookie } from 'nuxt/app';
import { baseCountryConfigs } from '#root/shared/config/country-config';

export default defineNuxtPlugin((app) => {
  const nuxtApp = useNuxtApp();
  const { brand } = useRuntimeConfig().public;

  const { pathname } = useRequestURL();

  const availableLocales = Object.values(baseCountryConfigs[brand])
    .flatMap((c) => c.i18n)
    .map((l) => l.code);

  const matchedLocale = availableLocales.find((locale) =>
    pathname.startsWith(`/${locale}`)
  );

  nuxtApp.$i18n.locale.value =
    matchedLocale || useRuntimeConfig().public.defaultLocale;

  const route = useRoute();

  const maxAge = 60 * 60 * 24 * 365; // 1 year

  const localeCookie = useCookie('locale', { maxAge });
  // Set locale cookie on first page load (except on country-select page and empty route which is also the country-select)
  if (route.name && route.name !== 'country-select') {
    localeCookie.value = app.$i18n.locale.value;
    setCookie('first-locale-check', 'initialized', maxAge);
  } else {
    // In the absence of our managed locale, it will either be empty or "contaminated" by the browser (even without the browser detection feature)
    // As such we have to override it with an accepted value that will be overriden by the user choice
    // Not having a locale entirely will cause the library to crash so we have to ensure we have one.
    // eslint-disable-next-line no-lonely-if
    if (
      !app.$i18n.locale.value ||
      !app.$i18n.locales.value
        .map((l) => l.code)
        .includes(app.$i18n.locale.value)
    ) {
      // eslint-disable-next-line no-param-reassign
      app.$i18n.locale.value = useRuntimeConfig().public.defaultLocale;
    }
  }

  // Set locale cookie when locale was changed
  // eslint-disable-next-line no-param-reassign
  app.$i18n.onLanguageSwitched = (_, newLocale) => {
    if (process.client) {
      localeCookie.value = newLocale;
    }
  };

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable no-param-reassign */
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.$i18n.t(`${field}`);
      return app.$i18n.t(`validation.${values._rule_}`, values);
    },
  });
  /* eslint-enable no-underscore-dangle */
  /* eslint-enable no-param-reassign */
});

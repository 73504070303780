declare global {
  interface Window {
    originalLocation?: string;
  }
}

const setOriginalLocation = () => {
  const isReload =
    (window.performance.navigation &&
      window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => (nav as PerformanceNavigationTiming).type)
      .includes('reload');

  const isCookieSet = document.cookie.includes('originalLocation');

  const isReferrerSameOrigin = document.referrer?.includes(
    window.location.origin
  );

  // @ts-ignore TODO: extend window interface with Cookiebot when implemented
  if (window?.Cookiebot?.consent?.statistics) {
    if ((!isReload && !isReferrerSameOrigin) || !isCookieSet) {
      window.originalLocation = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`;

      document.cookie = `originalLocation=${window.originalLocation}; path=/`;
    }
    return true;
  }
  return false;
};

export default defineNuxtPlugin(() => {
  if (process.env.APP_ENV !== 'test') {
    const hasConsented = setOriginalLocation();

    if (!hasConsented) {
      window.addEventListener('CookiebotOnAccept', setOriginalLocation);
    }
  }
});

import mixpanel from 'mixpanel-browser';

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  if (
    !config.public.mixpanel?.projectToken ||
    !config.public.mixpanel?.enabled
  ) {
    return;
  }

  mixpanel.init(config.public.mixpanel?.projectToken, {
    // eslint-disable-next-line func-names
    loaded: function (mixpanelInstance) {
      const growthbook = nuxtApp.$growthbook;

      if (growthbook) {
        growthbook.setAttributes({
          ...growthbook.getAttributes(),
          id: mixpanelInstance.get_distinct_id(),
        });

        growthbook.setTrackingCallback((experiment, result) => {
          mixpanel.track('$experiment_started', {
            'Experiment name': experiment.key,
            'Variant name': result.variationId,
            $source: 'growthbook',
          });
        });
      }
    },
  });
});

declare global {
  interface Window {
    userNavigatedBack?: boolean;
  }
}

export default defineNuxtPlugin(() => {
  const router = useRouter();

  router.beforeEach((_, from, next) => {
    const historyState = router.options.history.state;

    window.userNavigatedBack = from.fullPath === historyState.forward;

    next();
  });
});
